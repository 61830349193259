let variables: any = {};
let env = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
//local dev environment
if (env === 'dev') {
  variables = {
    LINKEDIN_STATE: 'a_random_string_that_is_really_difficult_and_random',
    LINKEDIN_SCOPE: 'r_liteprofile r_emailaddress',
    LINKEDIN_REDIRECT: `http://localhost:3000/api/pocs/oauth`,
    LINKEDIN_CLIENT_ID: '77mf72pnztixrs',
  };
 //deployed dev environmnet
} else if (env === 'development') {
  variables = {
    LINKEDIN_STATE: 'a_random_string_that_is_really_difficult_and_random',
    LINKEDIN_SCOPE: 'r_liteprofile r_emailaddress',
    LINKEDIN_REDIRECT: `https://dev.assettribe.io/api/pocs/oauth`,
    LINKEDIN_CLIENT_ID: '863ed2fpxdsti3',
  };
} else if (env === 'uat') {
  variables = {
    LINKEDIN_STATE: 'a_random_string_that_is_really_difficult_and_random',
    LINKEDIN_SCOPE: 'r_liteprofile r_emailaddress',
    LINKEDIN_REDIRECT: `https://uat.assettribe.io/api/pocs/oauth`,
    LINKEDIN_CLIENT_ID: '77dkz89vg4f7ob',
  };
} else if (env === 'production') {
  variables = {
    LINKEDIN_STATE: 'a_random_string_that_is_really_difficult_and_random',
    LINKEDIN_SCOPE: 'r_liteprofile r_emailaddress',
    LINKEDIN_REDIRECT: `https://invest.assettribe.io/api/pocs/oauth`,
    LINKEDIN_CLIENT_ID: '86yrw0di2s9ya7',
  };
}

export const LINKEDIN_VARIABLES: {
  LINKEDIN_STATE: string;
  LINKEDIN_SCOPE: string;
  LINKEDIN_REDIRECT: string;
  LINKEDIN_CLIENT_ID: string;
} = variables;
