import { useEffect, useRef } from 'react';
import { LINKEDIN_URL } from 'src/utils/linkedInUrl';

export default function LinkedInLogin() {
  return (
    <a href={LINKEDIN_URL}>
      <div style={{ height: '40px', width: '165px' }}>
        <img
          style={{ height: '100%', width: '100%' }}
          src={
            '/signInWithLinkedIN.png'
          }
          alt={'LinkedIn authentification'}
        />
      </div>
    </a>
  );
}
