export default () => {
  function clear_reports_ConversionTag_87() {
    var wgti = '{{TRANSACTION_ID}}';
    var wgtv = '{{TRANSACTION_VALUE}}';

    var cr_cid = 0;
    var tp_cid = '';
    var params = window.location.search.substring(1).split('&');
    for (var i = 0; i < params.length; i++) {
      var pair = params[i].split('=');
      if (pair[0] == 'cr_cid' && pair[1]) {
        cr_cid = parseInt(pair[1]);
      } else if (pair[0] == 'gclid' && pair[1]) {
        tp_cid = pair[1];
      }
    }
    var ca = document.cookie.split(';');
    for (var j = 0; j < ca.length; j++) {
      var c = ca[j].trim().split('=');
      if (!cr_cid && c[0] == 'cr___cid') {
        // @ts-ignore
        cr_cid = c[1];
      } else if (!tp_cid && c[0] == 'cr___tpcid') {
        tp_cid = c[1];
      }
    }
    var tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src =
      'https://www.clear-reports.com/track/conversion.php?wgi=7273&wgc=f03d1f32bd40283853377cda052c2cd4&pci=' +
      // @ts-ignore
      parseInt(cr_cid) +
      '&tpci=' +
      encodeURIComponent(tp_cid) +
      '&ht=1&cachebuster=' +
      Date.now() +
      '&wgtv=' +
      wgtv +
      '&wgti=' +
      wgti;
    document.body.appendChild(tag);
  }
  clear_reports_ConversionTag_87();
};
