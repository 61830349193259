import { OutlinedInputProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const StyledTextfield: React.FC<TextFieldProps> = ({
  InputProps,
  sx,
  ...props
}) => (
  <TextField
    InputProps={
      {
        notched: false,
        sx: {
          backgroundColor: 'rgb(250,246,230)!important',
          borderRadius: '4px',
        },
        ...InputProps,
      } as OutlinedInputProps
    }
    InputLabelProps={{
      disableAnimation: true,
      shrink: true,
      variant: 'standard',
      sx: {
        color: (theme) => theme.palette.info.contrastText,
        position: 'relative',
        lineHeight: '1em',
        textAlign: 'start',
        backgroundColor: 'none',
        '& .MuiInputLabel-asterisk': {
          color: 'red',
        },
        '&.Mui-disabled': {
          color: 'rgb(255, 255, 255, 0.38)',
        },
      },
    }}
    sx={{
      background: 'none',
      '&.MuiTextField-root': {
        background: 'none',
      },
      ...sx,
    }}
    {...props}
  />
);
