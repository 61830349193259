import { LINKEDIN_VARIABLES } from 'src/constants/linkedInVariables';

export const getURLWithQueryParams = (base: any, params: any) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as any)}`)
    .join('&');

  return `${base}?${query}`;
};

export const LINKEDIN_URL = getURLWithQueryParams(
  'https://www.linkedin.com/oauth/v2/authorization',
  {
    response_type: 'code',
    client_id: LINKEDIN_VARIABLES.LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_VARIABLES.LINKEDIN_REDIRECT,
    state: LINKEDIN_VARIABLES.LINKEDIN_STATE,
    scope: LINKEDIN_VARIABLES.LINKEDIN_SCOPE,
  }
);
