import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { colors } from '../../constants/colors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { useSnackAlert } from 'src/hooks/useSnackAlert';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import CloseIcon from '@mui/icons-material/Close';
import { event } from 'src/lib/gtag';
import LinkedInLogin from 'src/pages/pocs/linkedIn-login';
import { StyledTextfield } from '../common/StyledTextfield';
import convertionAnaliticsTag from 'src/utils/convertionAnaliticsTag';

export const ErrorHelperText = ({ error }: any) => {
  return (
    <Typography
      style={{
        position: 'absolute',
        left: 0,
        bottom: -4,
        width: '100%',
        backgroundColor: '#FBF6E4',
        textAlign: 'left',
        borderRadius: 4,
        paddingLeft: 16,
        paddingTop: 2,
        paddingBottom: 2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      color={'#ff1744'}
      variant="caption"
    >
      {error}
    </Typography>
  );
};

export const RegisterBox = ({ setOpenRegistrationModal }: any) => {
  const t = useTranslations('RegisterBoxFull');
  const t1 = useTranslations('Button');
  return (
    <Paper
      style={{
        padding: 30,
        backgroundColor: colors.darkBlack1,
        maxWidth: 450,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderColor: '#D4D5D966',
        textAlign: 'center',
      }}
      variant="outlined"
    >
      <Typography variant="h5" mb={4}>
        {t('heading')}
      </Typography>
      <TextField placeholder="Name" style={{ marginBottom: 20 }} />
      <TextField placeholder="Email Id" />
      <Button
        onClick={() => setOpenRegistrationModal(true)}
        variant="contained"
        sx={{ mt: 3, mb: 4 }}
      >
        {t1('registerNow')}
      </Button>
      <Typography color={colors.white1} fontSize={12} variant="body2" mb={3}>
        {t('proceeding')}{' '}
        <a
          href={'/wp-pages/terms-of-use'}
          style={{
            display: 'inline',
            cursor: 'pointer',
            color: colors.pink,
            textDecoration: 'none',
            fontSize: 12,
          }}
        >
          {t('terms')}
        </a>
        .
      </Typography>
      <Typography fontSize={14} color={colors.white1}>
        {t('alreadyAccount')}{' '}
        <Link passHref={true} href="/offerings">
          <span style={{ color: colors.pink, cursor: 'pointer' }}>
            {t('login')}
          </span>
        </Link>
      </Typography>
    </Paper>
  );
};

export const CustomCheckBox = ({
  children,
  name,
  onBlur,
  onChange,
  value,
  disabled = false,
}: {
  children: any;
  name: string;
  onBlur: any;
  onChange: any;
  value: boolean;
  disabled?: boolean;
}) => {
  return (
    <FormControlLabel
      disabled={disabled}
      style={{
        fontSize: 13,
        textAlign: 'left',
        marginBottom: 16,
        display: 'flex',
        alignItems: 'flex-start',
      }}
      componentsProps={{
        typography: { mt: 1, fontSize: 14, color: colors.white1 },
      }}
      control={
        <Checkbox
          sx={{ color: colors.white1}}
          value={name}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          checked={value}
        />
      }
      label={children}
    />
  );
};

export const RegisterBoxFull = ({
  openRegistrationModal,
  setOpenRegistrationModal,
  setOpenLoginModal,
}: any) => {
  const [showPassword, setShowPassowrd] = useState(false);
  const [showConfirmPassword, setConfirmShowPassowrd] = useState(false);
  const router = useRouter();
  const t = useTranslations('RegisterBoxFull');
  const t1 = useTranslations('Button');
  const breakModalWidth = useMediaQuery('(max-width:600px)');

  const showSnackAlert = useSnackAlert();

  const formikProps = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      otpVerified: false,
      confirmPassword: '',
    },
    onSubmit: async (values, actions) => {
      const request = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        middleName: '',
      };
      try {
        const output = await Axios.post('/api/users', request).then(
          (res) => res.data
        );
        event({
          action: 'register_complete',
          category: 'auth',
          label: '',
          value: '',
        });
        showSnackAlert(
          'success',
          'Account created successfully, please check your email for login OTP!'
        );
        sessionStorage.setItem(
          'registerSuccessEmail',
          formikProps.values.email
        );
        sessionStorage.setItem(
          'registerSuccessPassword',
          formikProps.values.password
        );
        sessionStorage.setItem('fromRegister', 'true');
        convertionAnaliticsTag();
        setOpenRegistrationModal(false);
        setOpenLoginModal(true);
        console.log(output);
        actions.resetForm();
      } catch (error: any) {
        console.log(error.response.data.data.message);
        showSnackAlert('error', error.response.data.data.message);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().label('Email').email().required(),
      password: Yup.string()
        .label('Password')
        .test(
          'passedRegex',
          'Uppercase, Lowercase and number required',
          //@ts-ignore
          (value) => value?.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
        )
        .min(10)
        .max(128)
        .required(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password')],
        'Passwords must match'
      ),
      firstName: Yup.string().label('First name').required(),
      lastName: Yup.string().label('Last name').required(),
    }),
  });

  const style = {
    position: 'absolute' as 'absolute',
    top: breakModalWidth ? 0 : '50%',
    left: '50%',
    transform: breakModalWidth
      ? 'translate(-50%, 0px)'
      : 'translate(-50%, -50%)',
    width: breakModalWidth ? '100%' : 600,
    background: colors.darkBlueGrey,
    my: breakModalWidth ? 0 : 2,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0 auto',
    maxHeight: breakModalWidth ? '100vh' : '90vh',
    outline: 0,
    padding: 3,
    paddingTop: 1,
    border: '1px solid rgba(212, 213, 217, 0.4)',
    borderRadius: '4px',
  };

  useEffect(() => {
    if (router.query.register && router.query.prefill) {
      Axios.get(`/api/wp/transfer?id=${router.query.prefill}`)
        .then((res) => res.data)
        .then((data) => {
          if (data.payload) {
            formikProps.setValues(data.payload);
          }
        });
    }
  }, [router.query]);

  return (
    <>
      <form onSubmit={formikProps.handleSubmit}>
        <Modal
          open={openRegistrationModal}
          onClose={() => setOpenRegistrationModal(false)}
          onBackdropClick={() => setOpenRegistrationModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="box" sx={style}>
            {breakModalWidth && (
              <IconButton
                onClick={() => {
                  setOpenRegistrationModal(false);
                }}
                style={{ position: 'absolute', right: 0 }}
              >
                <CloseIcon style={{ color: '#FBF6E4', fontSize: 25 }} />
              </IconButton>
            )}
            <Box
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              mb={breakModalWidth ? 2 : 4}
            >
              <Typography mt={3} mb={1} variant="h5" textAlign="center">
                {t('heading')}
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ position: 'relative' }}>
              <Grid item xs={12} sm={6} md={6}>
                <StyledTextfield
                  label="First Name"
                  fullWidth
                  required
                  value={formikProps.values.firstName}
                  name="firstName"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  error={Boolean(
                    formikProps.touched.firstName &&
                      formikProps.errors.firstName
                  )}
                  helperText={
                    formikProps.touched.firstName &&
                    formikProps.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <StyledTextfield
                  label="Last Name"
                  name="lastName"
                  required
                  fullWidth
                  value={formikProps.values.lastName}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  error={Boolean(
                    formikProps.touched.lastName && formikProps.errors.lastName
                  )}
                  helperText={
                    formikProps.touched.lastName && formikProps.errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <StyledTextfield
                  label="Email"
                  variant="outlined"
                  required
                  fullWidth
                  size="small"
                  name="email"
                  value={formikProps.values.email}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  error={Boolean(
                    formikProps.touched.email && formikProps.errors.email
                  )}
                  helperText={
                    formikProps.touched.email && formikProps.errors.email
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  style={{
                    position: 'relative',
                    flexDirection: 'column',
                    marginBottom: formikProps.errors.password ? 16 : 0,
                  }}
                >
                  <StyledTextfield
                    style={{
                      marginBottom: formikProps.touched.password ? 20 : 0,
                    }}
                    label="Password"
                    required
                    fullWidth
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formikProps.values.password}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={Boolean(
                      formikProps.touched.password &&
                        formikProps.errors.password
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassowrd(!showPassword)}
                            onMouseDown={() => setShowPassowrd(false)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {Boolean(
                    formikProps.touched.password && formikProps.errors.password
                  ) && (
                    <Typography
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: -4,
                        width: '100%',
                        backgroundColor: '#FBF6E4',
                        textAlign: 'left',
                        borderRadius: 4,
                        paddingLeft: 16,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                      color={'#ff1744'}
                      variant="caption"
                    >
                      {formikProps.errors.password}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div style={{ position: 'relative', flexDirection: 'column' }}>
                  <StyledTextfield
                    label="Confirm Password"
                    required
                    size="small"
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={formikProps.values.confirmPassword}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={Boolean(
                      formikProps.touched.confirmPassword &&
                        formikProps.errors.confirmPassword
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setConfirmShowPassowrd(!showConfirmPassword)
                            }
                            onMouseDown={() => setConfirmShowPassowrd(false)}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {Boolean(
                    formikProps.touched.confirmPassword &&
                      formikProps.errors.confirmPassword
                  ) && (
                    <Typography
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: -4,
                        width: '100%',
                        backgroundColor: '#FBF6E4',
                        textAlign: 'left',
                        borderRadius: 4,
                        paddingLeft: 16,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }}
                      color={'#ff1744'}
                      variant="caption"
                    >
                      {formikProps.errors.confirmPassword}
                    </Typography>
                  )}
                </div>
              </Grid>
              {!Boolean(formikProps.touched.password) && (
                <div style={{ textAlign: 'left' }}>
                  <Typography sx={{ fontSize: 12, ml: 2 }}>
                    * Uppercase, lowercase and number required. Minimum 10
                    characters required.
                  </Typography>
                </div>
              )}
              <Grid xs={12} sm={12} item>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={formikProps.isSubmitting}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                >
                  {t1('registerNow')}
                </Button>
              </Grid>
              <Typography
                mt={2}
                textAlign="center"
                width={'100%'}
                variant="body1"
              >
                OR
              </Typography>
              <Grid xs={12} sm={12} item>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 10,
                  }}
                >
                  <LinkedInLogin />
                </div>
              </Grid>
              <Grid xs={12} sm={12} item>
                <Typography
                  fontSize={14}
                  color={colors.white1}
                  style={{ textAlign: 'center' }}
                >
                  {t('alreadyAccount')}{' '}
                  <span
                    onClick={() => {
                      setOpenRegistrationModal(false);
                      setOpenLoginModal(true);
                    }}
                    style={{ color: colors.pink, cursor: 'pointer' }}
                  >
                    {t('login')}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </>
  );
};
